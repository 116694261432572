import React from 'react';
import './index.css';
import ChennaiPlus from './assets/Chennai_Plus.jpg';

function App() {
  const handleButtonClick = (url) => {
    window.location.href = url;
  };

  return (
    <div className='h-screen bg-gradient-to-br from-blue-100 via-purple-100 to-pink-100 animate-gradient'>
      <header className='p-2 flex justify-center'>
        <img
          src={ChennaiPlus}
          alt="Chennai Plus"
          className="w-56 max-w-xs object-contain"
        />
      </header>
      <div className="mt-28">
        <div className="text-center">
          <h1 className="text-4xl text-red-600 font-bold mb-3">Coming Soon!!!</h1>
          <p className="text-xl font-serif mb-3">We're working hard on something amazing. Stay tuned!✨</p>
          <div className='space-x-4'>
            <button
              className="bg-red-500 text-white font-bold text-lg px-8 py-4 rounded-lg shadow-lg hover:bg-red-600 hover:shadow-xl active:bg-red-700 transition-all duration-300 transform hover:scale-105"
              onClick={() =>
                handleButtonClick('https://mates.extraa.in/csv/jito_chennai_+_membership_survey')
              }
            >
              Membership Form
            </button>
          </div>
        </div>
      </div>
      <footer className="bg-slate-800 text-white py-6 mt-28">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-4 text-center md:text-left">
          <div>
            <h3 className="text-lg font-bold mb-2">Address</h3>
            <p>313, Refex Towers, Valluvar Kottam High Road, Nungambakkam High Road, Chennai, Chennai- 600034, Tamil Nadu</p>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-2">Contact</h3>
            <p className='mb-2'>Phone: +91 9345887874</p>
            <p>Email: hello@jitochennaiplus.org</p>
          </div>
          <div>
          <h3 className="text-base font-bold mb-2 mt-1">PAN: AAGCJ5847F*</h3>
          <h3 className="text-base font-bold mb-2 mt-1">TAN: CHEJ11844A*</h3>
          </div>
        </div>
        <div className="mt-6 text-center text-sm border-t border-gray-500 pt-4">
          © 2024 Jito Chennai Plus. All rights reserved.
        </div>
      </footer>
    </div>
  );
}

export default App;
